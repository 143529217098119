import React, { ReactNode } from 'react';
import Close from '@mui/icons-material/Close';
import { Dialog } from '@mui/material';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';
import Transition from './Transition';

interface BasicModalProps {
  show: boolean;
  onClose: () => void;
  children: ReactNode | undefined;
  heading?: string;
  showCloseIcon?: boolean;
  customCloseIcon?: any;
  closeIconClass?: string;
  customClasses?: string;
  headingClasses?: string;
}

/**
 * Modal with a sticky header, having border at bottom.
 * Add the additional parameters here if required.
 */
const BasicModal: React.FC<BasicModalProps> = ({
  show,
  onClose,
  children,
  heading = '',
  showCloseIcon = true,
  customCloseIcon = null,
  closeIconClass = '',
  customClasses = '',
  headingClasses = '',
}) => {
  const defaultContainerClasses = 'tw-rounded-[8px_8px_0px_0px] md:tw-rounded-[8px]';
  const defaultHeadingClass = 'tw-text-[18px]/[20px] tw-font-semibold';
  const defaultCloseIconClass =
    'tw-absolute tw-right-[16px] tw-top-[16px] tw-cursor-pointer';

  const getCloseIconIfAny = () => {
    return showCloseIcon ? (
      customCloseIcon ? (
        customCloseIcon
      ) : (
        <Close
          fontSize="small"
          className={`${defaultCloseIconClass} ${closeIconClass}`}
          onClick={onClose}
        />
      )
    ) : null;
  };

  return (
    <Dialog
      className=""
      open={show}
      onClose={onClose}
      classes={{
        paper: `${!IS_DESKTOP ? 'tw-absolute tw-bottom-0' : ''} tw-w-full md:tw-min-w-[672px] tw-m-0 ${defaultContainerClasses} ${customClasses}`,
        root: '',
      }}
      TransitionComponent={Transition}
    >
      <div className="tw-relative">
        <div className="tw-border-gray1 tw-sticky tw-top-0 tw-z-10 tw-flex tw-justify-between tw-border-b tw-border-solid tw-bg-white tw-px-[24px] tw-py-[16px]">
          <div className={`${defaultHeadingClass} ${headingClasses}`}>
            {heading || ''}
          </div>
          {getCloseIconIfAny()}
        </div>
        <div>{children}</div>
      </div>
    </Dialog>
  );
};

export default BasicModal;
