/**
 * Common Layout
 */
import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { getRoute, PATH } from '@/utils/routes';
import {
  fetchFeatureLocks,
  getB2bRfqInputFields,
  getCustomerReviewSettings,
  saveStoreInfo,
  toggleInaccessibleView,
} from '@/redux/actions';
import LocalStorageHelper from '@/utils/LocalStorageHelper';
import { ThemeCss } from './theme';
import { RichSnippetStoreDetails } from './RichSnippetStoreDetails';
import { useFeatureLocks } from '@/hooks/useFeatureLocks';
import { B2B_STORE_TYPE_CONSTANT } from '@/utils/constants';
import dynamic from 'next/dynamic';
import { useSSRSelector } from '@/redux/ssrStore';
import { DEFAULT_SSR_IMAGE_URL } from '@/utils/constants/images';
import checkIfThemeEditorOrPreview from '@/utils/checkIfThemeEditorOrPreview';
import PromoModal from '../CouponsAndVouchers/PromoModal';
import PromoRemovalAlertModal from '../CouponsAndVouchers/PromoRemovalAlertModal';

const B2bRfqFormModal = dynamic(() =>
  import('@/containers/ProductDescription/Components/B2b/B2bRfqFormModal').then(
    (mod) => mod.B2bRfqFormModal
  )
);
const RedirectionToWebConsoleModal = dynamic(
  () => import('../RedirectionToWebConsoleModal')
);
const InaccessibleLayer = dynamic(() => import('../InaccessibleLayer'));

const ExitGatingModal = dynamic(() => import('../ExitGatingModal'));

const IS_SERVER = typeof window === 'undefined';

const Layout = ({
  store,
  title = '',
  Icon = '',
  favIcon = '',
  metaDescription = '',
  OGTitle = '',
  OGDescription = '',
  OGImage = '',
  children,
  keywords = '',
  ...props
}) => {
  const dispatch = useDispatch();
  const { storeData, shouldLoadFeatureLocks } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
    shouldLoadFeatureLocks: !state.commonReducer.featureLocksData.isFeatureLocksLoaded,
  }));
  const { userData, toggleMobileView, isLoginByEmail } = useSelector((state) => ({
    userData: state.userReducer,
    toggleMobileView: state.commonReducer.toggleMobileView,
    isLoginByEmail: !!state.userReducer.data?.email,
  }));
  const storeInfo = store || storeData;
  const storeId = props?.storeInfo?.store_id || storeData?.store_id;

  const router = useRouter();
  const { memberOnlyLoginFeatureStatus } = useFeatureLocks();
  const isMolUnlocked = memberOnlyLoginFeatureStatus?.isLocked === false;
  const [showState, setShowState] = useState({
    showInaccessibleView: false,
    showRedirectionModal: false,
  });

  const { fbPixelFeatureStatus, googleTagFeatureStatus, googleAnalyticsFeatureStatus } =
    useFeatureLocks();

  const isBrandWebsiteDemo = router.pathname.includes('/brand-website-theme-demo');
  useEffect(() => {
    if (checkIfThemeEditorOrPreview() && !isBrandWebsiteDemo) {
      document.getElementsByTagName('body')[0].style = 'pointer-events: none';
    }
    if (
      storeInfo?.services?.store_type_flag === B2B_STORE_TYPE_CONSTANT &&
      userData?.isLogin
    ) {
      dispatch(getB2bRfqInputFields(undefined, storeInfo?.store_id));
    }
  }, []);

  useEffect(() => {
    const shouldRedirectToMOL =
      !checkIfThemeEditorOrPreview() &&
      isMolUnlocked &&
      storeInfo?.services?.members_only_access &&
      !LocalStorageHelper.get(`isMemberAllowed&store_id=${storeInfo?.store_id}`) &&
      !router.pathname.includes(PATH.MEMBER_LOGIN);

    if (shouldRedirectToMOL) {
      const memberLoginRoute = getRoute(PATH.MEMBER_LOGIN, storeInfo?.store_info?.domain);
      router.replace(memberLoginRoute);
    }
  }, [isMolUnlocked]);

  /**
   * Instead of putting this sequence of code in every container,
   * we can put it here and reduce the number of lines of code.
   * If there are any special ones, the can be put in respective
   * containers.
   *
   * These four are common and used thorughout the pages and containers
   */
  useEffect(() => {
    /* Save store info */
    if (Object.keys(props?.storeInfo || {}).length) {
      dispatch(saveStoreInfo(props?.storeInfo || {}));
    }
  }, [props?.storeInfo]);

  useEffect(() => {
    if (storeId) {
      dispatch(getCustomerReviewSettings(storeId));
    }
  }, [storeId]);

  useEffect(() => {
    const storeInfo = store || storeData;
    const route = getRoute(PATH.PRODUCT, storeInfo?.store_info?.domain);
    if (
      storeInfo.store_id &&
      storeInfo.domain_info.status === 2 &&
      !route.includes(location.pathname)
    ) {
      router.replace(route);
    }
  }, [storeData]);

  useEffect(() => {
    if (storeId && shouldLoadFeatureLocks) dispatch(fetchFeatureLocks(storeId));
  }, [storeId, shouldLoadFeatureLocks]);

  const loginBtnClick = () => {
    setShowState((state) => ({
      ...state,
      showRedirectionModal: true,
    }));
  };

  const showIframeView = () => {
    dispatch(toggleInaccessibleView(true));
    setShowState((state) => ({
      ...state,
      showInaccessibleView: false,
    }));
  };

  return (
    <div className="dd-showrom__layout tw-font-inter">
      <Head>
        <title>{title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={keywords} />
        <meta property="og:title" content={OGTitle || title} />
        <meta property="og:description" content={OGDescription || metaDescription} />
        <meta property="og:image" content={OGImage || Icon || DEFAULT_SSR_IMAGE_URL} />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
        {/* Twitter Meta Tags  */}
        <meta name="twitter:title" content={OGTitle || title} id="twittertitle" />
        <meta
          name="twitter:description"
          content={OGDescription || metaDescription}
          id="twitterdescription"
        />
        <meta
          name="twitter:image"
          content={OGImage || Icon || DEFAULT_SSR_IMAGE_URL}
          id="twitterimage"
        />
        <meta name="twitter:card" content="summary" />
        {/** Google Site Verification */}
        {store?.store_marketing_tags?.gmc_verification && (
          <meta
            name="google-site-verification"
            content={store?.store_marketing_tags?.gmc_verification}
          />
        )}

        {store?.store_marketing_tags?.fb_domain_verification && (
          <meta
            name="facebook-domain-verification"
            content={store?.store_marketing_tags?.fb_domain_verification}
          />
        )}
        {/* Adding canonical link that points to current page in SEO */}
        <link rel="canonical" href={`${store?.store_info?.store_url}${router?.asPath}`} />
        <link
          rel="shortcut icon"
          type="image/png"
          href={storeData?.favicon || DEFAULT_SSR_IMAGE_URL}
        />
        <link
          rel="manifest"
          href={`/api/manifest?domain=${encodeURIComponent(
            store?.store_info?.domain
          )}&banner=${encodeURIComponent(store?.store_info?.name)}&logo=${encodeURI(
            store?.store_info?.logo_image
          )}&url=${encodeURI(store?.store_info?.store_url)}`}
        />
        {/* Facebook pixel code */}
        {store?.store_marketing_tags?.facebook_pixel &&
          !fbPixelFeatureStatus?.isLocked && (
            <>
              <script
                dangerouslySetInnerHTML={{
                  __html: `
                  !function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window, document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', '${store?.store_marketing_tags.facebook_pixel}');
                  fbq('track', 'PageView');
                `,
                }}
              />
              <noscript>
                <img
                  height="1"
                  width="1"
                  style={{ display: 'none' }}
                  src={`https://www.facebook.com/tr?id=${store?.store_marketing_tags.facebook_pixel}&ev=PageView&noscript=1`}
                />
              </noscript>
            </>
          )}

        {/* Google tag manager */}
        {store?.store_marketing_tags?.google_analytics &&
          !googleAnalyticsFeatureStatus?.isLocked && (
            <>
              <script
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${store?.store_marketing_tags.google_analytics}`}
              ></script>
              <script
                dangerouslySetInnerHTML={{
                  __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${store?.store_marketing_tags.google_analytics}');
                `,
                }}
              />
            </>
          )}

        {/* Google ads */}
        {store?.store_marketing_tags?.google_ads && (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${store.store_marketing_tags.google_ads}`}
            ></script>
            <script
              dangerouslySetInnerHTML={{
                __html: `
                 window.dataLayer = window.dataLayer || [];
                 function gtag(){dataLayer.push(arguments);}
                 gtag('js', new Date());
 
                 gtag('config', '${store.store_marketing_tags.google_ads}');
               `,
              }}
            />
          </>
        )}

        {/** Google Tag manager (Specific to the user) */}
        {store?.store_marketing_tags?.g_tag && !googleTagFeatureStatus?.isLocked ? (
          <>
            <script
              async
              src={`https://www.googletagmanager.com/gtag/js?id=${store?.store_marketing_tags?.g_tag}`}
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                '//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','${store?.store_marketing_tags?.g_tag}');
                `,
              }}
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${store?.store_marketing_tags?.g_tag}');`,
              }}
            />
          </>
        ) : (
          <>
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-LLCH0NYZ61"
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', 'G-LLCH0NYZ61');`,
              }}
            />
          </>
        )}

        {/* <!-- Matomo --> */}
        {/* experimental matomo code for temporary testing */}
        {store?.domain_info?.site_id ? (
          <script
            dangerouslySetInnerHTML={{
              __html: `
              var _paq = window._paq = window._paq || [];
              /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
              _paq.push(['trackPageView']);
              _paq.push(['enableLinkTracking']);
              (function() {
                var u="//matomo.dotshowroom.in/";
                _paq.push(['setTrackerUrl', u+'matomo.php']);
                _paq.push(['setSiteId', ${store?.domain_info?.site_id}]);
                var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
              })();
            `,
            }}
          />
        ) : null}
        {/* <!-- End Matomo Code */}
      </Head>
      <ThemeCss />
      <RichSnippetStoreDetails store={store} />
      {/** Google Tag manager (Specific to the user), body fallback */}
      {store?.store_marketing_tags?.g_tag && (
        <noscript>
          <iframe
            src={`//www.googletagmanager.com/ns.html?id=${store?.store_marketing_tags?.g_tag}`}
            height="0"
            width="0"
            title="Google Tag Manager"
            style={{
              display: 'none',
              visibility: 'hidden',
            }}
          ></iframe>
        </noscript>
      )}
      {store?.domain_info?.status !== 2 && toggleMobileView ? (
        !IS_SERVER && (
          <div className="mobile-screen relative">
            <div className="inaccessibleLayer-login flex items-center">
              <div>
                <p>If you are the owner of this website then</p>
                <p>login & enable desktop theme</p>
              </div>
              <span
                className="login-btn pointer relative dib ml3"
                onClick={loginBtnClick}
              >
                Log In
              </span>
            </div>
            <section className="flex">
              <iframe src={window.location.href} height={'100%'} width={'100%'} />
            </section>
          </div>
        )
      ) : (
        <>{children}</>
      )}
      {!!showState.showInaccessibleView ? (
        <InaccessibleLayer
          store={store}
          show={showState.showInaccessibleView}
          redirectToBadShowcase={showIframeView}
        />
      ) : null}
      {!!showState.showRedirectionModal ? (
        <RedirectionToWebConsoleModal show={showState.showRedirectionModal} />
      ) : null}
      {storeData?.services?.store_type_flag === B2B_STORE_TYPE_CONSTANT ? (
        <B2bRfqFormModal productData={props?.product || {}} />
      ) : null}
      {storeData?.services?.exit_gating_flag && !isLoginByEmail ? (
        <ExitGatingModal />
      ) : null}
      <PromoModal />
      <PromoRemovalAlertModal />
    </div>
  );
};

export default Layout;
